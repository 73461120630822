import React from 'react'
import Helmet from 'react-helmet'
import Breadcrumbs from '../components/breadcrumbs'
import Layout from "../components/layout"
import DocumentiUtili from '../components/documenti-utili'
import NavMenu from '../components/nav-menu'
import Contatti from '../components/contatti'
import PhotoGallery from '../components/photogallery'
import { graphql } from "gatsby"


class RepartoInternaTemplate extends React.Component {

  render() { 
    const reparto = this.props.data.reparto;
    const pagina = this.props.data.pagina;
    const pagineFiglie = [{titolo: reparto.titolo, friendlyUrl: reparto.friendlyUrl}].concat(reparto.pagineFiglie);
    const contatti = {
      contatti: "Per maggiori informazioni puoi contattaci a questi riferimenti",
      indirizzo: "via dell’Università, 26900 Lodi",
      telefono: reparto.telefono || "02.50334111",
      email: reparto.email || "segreteriacentrovet@unimi.it"
    }
   
    const breadcrumb = [
      {"title":"Reparti","link":"/reparti"},
      {"title": reparto.titolo,"link": "/"+reparto.friendlyUrl},
      {"title": pagina.titolo}
    ];

    return (
      <Layout  location={this.props.location}>
        <div className="container">
          <Helmet title={`${pagina.titolo} ${reparto.titolo}`}/>
          <Breadcrumbs breadcrumb={breadcrumb}/>
          <h1 className="servizi__title">{pagina.titolo}</h1>
          <div className="row">
            <div className="col-md-8">
              <div className="bodytext" dangerouslySetInnerHTML={{ __html: pagina.testo.childMarkdownRemark.html }} >
                
              </div>
              {!!pagina.fotogallery &&
                <div className="magnific-popup">
                  <h2>Galleria fotografica</h2>
                  <PhotoGallery data={pagina.fotogallery}/>
                </div>
              }    
            </div>
            <div className="col-md-4">
              {
                <NavMenu  active={pagina.friendlyUrl} data={pagineFiglie}/>
              }
              
              {!!reparto.documentiUtili &&
                <DocumentiUtili section="Documenti Utili" data={reparto.documentiUtili}/>
              }
              {!!pagina.attachment &&
                <DocumentiUtili section="Documenti Utili" data={pagina.attachment}/>
              }
              <Contatti data={contatti}/>
            </div>
          </div>
        </div>
      </Layout>
    )
  }

}

export default RepartoInternaTemplate;

export const pageQuery = graphql`query RepartoInternaDetailQuery($reparto_id: String!, $contentful_id: String!) {
  pagina: contentfulContenutoGenerico(contentful_id: {eq: $contentful_id}) {
    titolo
    friendlyUrl
    testo {
      childMarkdownRemark {
        html
      }
    }
    fotogallery {
      ...PhotoGalleryFragment
    }
    attachment {
      title
      file {
        url
      }
    }
  }
  reparto: contentfulReparto(contentful_id: {eq: $reparto_id}) {
    immagine {
      fluid(maxWidth: 570){
        ...GatsbyContentfulFluid_withWebp
      }
    }
    titolo
    friendlyUrl
    telefono
    email
    descrizione {
      childMarkdownRemark {
        html
      }
    }
    documentiUtili {
      title
      file {
        url
      }
    }
    pagineFiglie {
      friendlyUrl
      titolo
    }
  }
}
`;
